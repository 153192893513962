export const playerApiKey = (pId: string): string[] => {
  return ["account", `player:${pId}`];
};

export const rolesApiKey = () => {
  return ["roles"];
};

export const sportsApiKey = () => {
  return ["sports"];
};

export const accountApiKey = () => {
  return ["account"];
};

export const analyticsApiKey = (tId: string) => {
  return [`analytics:${tId}`];
};

export const teamApiKey = (tId: string) => {
  return ["account", `team:${tId}`];
};

export const submissionApiKey = (sId: string) => {
  return [`submission:${sId}`];
};
export const playerDatasetsApiKey = (pId: string) => {
  return [`playerDataset:${pId}`];
};
export const goalDatasetsApiKey = (gId: string) => {
  return [`goalDataset:${gId}`];
};
export const submissionsApiKey = (tId: string, pId: string) => {
  return [`team:${tId}`, `player:${pId}`];
};

export const dataSetApiKey = (dsId: string) => {
  return [`dataSet:${dsId}`];
};

export const scorecardApiKey = (sId: string) => {
  return [`scorecard:${sId}`];
};
export const scorecardWithMetricApiKey = (sId: string, mId: string) => {
  return [`scorecard:${sId}`, `metric:${mId}`];
};

export const teamRosterApiKey = (tId: string) => {
  return [`roster:${tId}`];
};
